/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps"
import Marker, { DropShadow } from './Marker'
import NZGrid from './data/NZGrid.json'
import PriceLocations from './data/PriceLocations.json'
import PriceRanges from './data/PriceRanges.json'
import { Legend } from '../graphs'
import useZoom from './useZoom'
import { trailingZero } from 'utils'

const SERIES = PriceRanges.reduce((acc, curr) => ({...acc, [curr.id]: curr}), {})

const RegionalPrice = ({data, zoomCallback}) => {

  const [visibleSeries, setVisibleSeries] = useState(Object.keys(SERIES))

  const priceRange = price => price ? PriceRanges.find(range => range.max > price || !range.max) : null

  const itemsByGridZoneId = data?.items?.reduce((acc, curr) => ({
    ...acc,
    [curr.gridZoneId]: {...curr, priceRange: priceRange(curr.price)}
  }), {}) || {}

  const {position, zoomableGroupProps} = useZoom({coordinates: [173, -41.1], zoomCallback})

  return (
    <div className='map' css={styles.container}>
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{
          center: [0,0],
          scale: 2070 * position.zoom
        }}
        fill='white'
      >
        <defs>
          <DropShadow/>
        </defs>

        <ZoomableGroup {...zoomableGroupProps}>
          <Geographies geography={NZGrid}>
            {({ geographies }) =>
              geographies.map((geo) => {

                const item = itemsByGridZoneId[geo.properties.GZ]
                const color = item?.priceRange?.color

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={color}
                  />)
              })
            }
          </Geographies>

          {PriceLocations.map(({gridZoneId, gridZoneName, longitude, latitude, xOffset}) => {

            const item = itemsByGridZoneId[gridZoneId]
            const visible = !item || visibleSeries.includes(String(item?.priceRange?.id))
            const text = item?.price ? `$${trailingZero(item?.price)}` : ' - '
            const color = item?.priceRange?.color
            const textColor = item?.priceRange?.textcolor || '#fff'

            return (
              item && <Marker
                        key={gridZoneId}
                        coordinates={[longitude, latitude]}
                        dx={xOffset}
                        title={gridZoneName}
                        text={text}
                        visible={visible}
                        labelStyle={{fill: color}}
                        markerStyle={{fill: color, stroke: '#fff'}}
                        textStyle={{fill: textColor}}
                      />
            )})}

        </ZoomableGroup>
      </ComposableMap>
      {/* disable map legend for now while we're getting UI feedback
      <Legend
        series={SERIES}
        visible={visibleSeries}
        onSeriesToggle={setVisibleSeries}
      />
      */}
    </div>
  )
}

const styles = {
  container: {
  },
}

export default RegionalPrice